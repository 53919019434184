import {ArtTypeEnum, VideoAscii} from 'video-stream-ascii';
import VideoController from './VideoController';
import CopyImage from '../../images/copy.svg';
import React, {useRef, useState} from 'react';
import './VideoViewPanel.scss';
import imglogo from './logo.png';
const imgSrc: string = imglogo as string;

type VideoViewPanelProps = {
	videoRef: HTMLVideoElement;
	charsPerLine: number;
	charsPerColumn: number;
	onEjectVideo: () => void;
};

export const VideoViewPanel = (props: VideoViewPanelProps) => {
	const [useColor, setUseColor] = useState(false);
	const divVideoAsciiParentRef = useRef<HTMLDivElement>(null);
	const preTagRef = useRef<HTMLPreElement>(null);

	return (
		<>
			<div className={'video-ascii-panel'}>
				<div ref={divVideoAsciiParentRef} className={'video-ascii-holder'}>
					<VideoAscii videoStreaming={props.videoRef}
						parentRef={divVideoAsciiParentRef}
						charsPerLine={props.charsPerLine}
						charsPerColumn={props.charsPerColumn}
						fontColor={'white'}
						backgroundColor={'black'}
						artType={ArtTypeEnum.ASCII_COLOR_BG_IMAGE}
						preTagRef={preTagRef}
					/>
				</div>

				<img src={imgSrc} className='logo' id='logoicon' />
			</div>
		</>
	);
};

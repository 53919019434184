import React, {forwardRef, useImperativeHandle, useState} from 'react';
import './VideoHandler.scss';
import {AUTHOR, GITHUB_URL} from '../../constants/github-project-constants';
import {AutoResolutionSelector} from './resolution-parameters/AutoResolutionSelector';
import {ManualResolutionSelector} from './resolution-parameters/ManualResolutionSelector';
import Video from './video.mp4';
import Siren from './siren.mp3';
import imgphone from './nophone.png';
const imgSrc: string = imgphone as string;

type Props = {
	videoRef: React.RefObject<HTMLVideoElement>;
	onCanPlay: () => void;
	setCharsPerLine: (charsPerLine: number) => void;
	setCharsPerColumn: (charsPerColumn: number) => void;
	autoPlay?: boolean;
};

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

export type VideoHandlerRef = {
	ejectVideo: () => void;
};

export const VideoHandler = forwardRef((props: Props, ref) => {
	const inputRef = React.useRef<HTMLInputElement>(null);
	const [videoUrl, setVideoUrl] = useState<string | undefined>(undefined);
	const autoPlay = props.autoPlay ?? true;

	// Mode resolution selection
	const [useAutoAspectRatio, setUseAutoAspectRatio] = useState(true);

	// Settings for manual resolution
	const [manualCharsPerLine, setManualCharsPerLine] = useState(160);
	const [manualCharsPerColumn, setManualCharsPerColumn] = useState(90);

	// Settings to calculate the chars per line/column based on the image aspect ratio and a selected line/column base
	const [autoResolutionBase, setAutoResolutionBase] = useState(100);
	const [useLineBase, setUseLineBase] = useState(true);

	const screenWidth = window.screen.width;
	const screenHeight = window.screen.height;

	const CalculatedCharsPerLine = Math.round(100 * (screenWidth / screenHeight));
	const CalculatedCharsPerColumn = Math.round(160 * (screenHeight / screenWidth));
	useImperativeHandle(ref, () => ({
		ejectVideo() {
			setVideoUrl(undefined);
		},
	}));

	return (
		<>
			{videoUrl
				? (
					<div>
						<video ref={props.videoRef} src={videoUrl}
							style={{width: 0, height: 0, position: 'absolute', top: 0, left: 0}}
							onCanPlay={() => {
								props.setCharsPerLine(CalculatedCharsPerLine);
								props.setCharsPerColumn(CalculatedCharsPerColumn);

								props.onCanPlay();
							}}
							onPlaying={() => {
								console.log('played');
								setTimeout(() => {
									const resizelogo = document.getElementById('logoicon');
									if (resizelogo !== null) {
										resizelogo.style.opacity = '80';
										resizelogo.style.width = '1000px';
										resizelogo.style.height = '700px';
									}
								}, 9500);
							}}
							autoPlay={autoPlay}
						/>
					</div>
				)
				: (
					<>

						<div className={'image-input-container'}>
							<div className={'image-input-button'}>
								<button className={'video-input-button'} id='clickbtn' onClick={() => {
									console.log('clicked');
									if (isMobile) {
										new Audio(Siren).play().then(() => {
											const clickbtn = document.getElementById('clickbtn');
											if (clickbtn !== null) {
												clickbtn.style.opacity = '0';
											}

											const nophone = document.getElementById('nophoneimg');
											if (nophone !== null) {
												nophone.style.opacity = '100';
												nophone.style.width = '150px';
												nophone.style.height = '150px';
											}
										}).catch(error => {
											console.error('failed:', error);
										});
									} else {
										setVideoUrl(Video);
									}
								}}>[ click ]
								</button>
								<img src={imgSrc} className='nophone' id='nophoneimg' />
							</div>
						</div>
					</>
				)
			}
		</>
	);
});

VideoHandler.displayName = 'VideoHandler';
